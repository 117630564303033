import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { NetworkType } from "@airgap/beacon-sdk";
import { CONTRACT_ADDRESS } from "@/utils";

const network = { type: NetworkType.GHOSTNET };
// const network = { type: NetworkType.MAINNET };

const Tezos = new TezosToolkit("https://ghostnet.tezos.marigold.dev");
// const Tezos = new TezosToolkit("https://mainnet.tezos.marigold.dev/");

const wallet = new BeaconWallet({
  name: "DFGHOST",
  preferredNetwork: network.type,
}); 

Tezos.setWalletProvider(wallet);
const getContract = async () => await Tezos.wallet.at(CONTRACT_ADDRESS);

export default {

  async fetchFrame({ commit }, shortID) {
    try {
      const contract = await getContract();

const storage = await contract.storage();
let map = storage.frames.valueMap

map.forEach(function(key) {
	
  if(Number(shortID) === key.id.c[0]) {
    commit("updatePkh", key.frame);
    commit("updateConnected", true);
  }
})
    } catch (error) {
      console.log(error);
    }
  }
};