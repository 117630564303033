import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify'

 Vue.use(VueRouter)
 const router = new VueRouter({ mode: 'history',
  routes: [
    { path: '/', component: App },
    { path: '/:id', component: App },
  ]
});

Vue.config.devtools = false
Vue.config.productionTip = false

if (module.hot) {
  module.hot.accept()

  module.hot.addStatusHandler((status) => {
    if (status === 'prepare') console.clear()
  })
}

console.log("diyFrame Player by Andre Fuchs");
console.log("2023, https://andrefuchs.xyz");
console.log("🦊️");

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')