<template>
  <v-app id="app" class="black" >

    <template v-if="this.$route.path === '/'">
   
      <v-container
        height="100vh"
        width="100vh"
        fill-height
        fluid
         class="justify-center align-center black"
      >
    <v-card
      class="mx-auto"
      max-width="344"
      flat

    >
     
      <v-img
        src="../src/assets/diyframe_word-picture_raw-path_svg-inline.svg"
        max-width="344px"
        
        class="mx-auto mt-4"
      ></v-img>

      <v-card-title>
ENTER A DIYFRAME ID
      </v-card-title>
      <v-card-text>
 
         <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="validate"
    >
     <v-text-field
        v-model="frameID"
        :rules="frameIDRules"
        label="Enter diyFrame ID"
        required
        type="number"       
               
                    filled
                   flat placeholder="123"
                    outlined
      ></v-text-field>
 </v-form>
        
  </v-card-text>
        <v-card-actions>
        <v-btn
        :disabled="!valid"
             depressed

              x-large
        color="#FF5400"
         width="330"
        class="mr-4"
        @click="validate"
      >
        PLAY
      </v-btn>
      </v-card-actions>
    </v-card>
      </v-container>
    </template>
<!--
  <v-icon>mdi-play-circle-outline</v-icon>

  #frame 
 <template v-if="this.$route.path !=='/' && this.$route.path !== '/frames' && this.$route.path !=='/explore'  && this.$route.path !== '/about'  && this.$route.path !== '/home' ">




TODO
play.diyframe.xyz/32
>params.id

LOADING INFO

<template v-if="this.$route.path.indexOf('/frame/') !== -1">
-->



  <!--
  TODO ENDE:
404 in cool


  && starts with tz || min 1 char?
= is showframe

#go 

 v-if="!loadedEntries"
-->




    <!-- TODO BRANDING  
      <v-toolbar-title class="grey--text">DIYFRAME.XYZ <small>by Andre Fuchs</small></v-toolbar-title> -->

    <!-- 
<v-toolbar  color="black">

        <v-btn
      
  color="black"
      large
               outlined
                @click="openLink('https://diyframe.xyz/?ref=frametz')"
       
      >
 
       <img height="50" class="mr-4" src="@/assets/logo.png" /><span class="grey--text headline">DIYFRAME</span></v-btn><small  @click="openLink('https://andrefuchs.xyz/?ref=frame')" class="grey--text subtitle">by Andre Fuchs</small>

       </v-toolbar >
-->

<!--  TODO? @touchstart="mouseMove()" @touchmove="mouseMove() 

hideCursor 
-->


<template v-else>
<v-container
      @mousemove="mouseMove()"
       @touchmove="mouseMove()"
        height="100vh"
        width="100vh"
        fluid
         class="d-flex justify-center align-center black ma-0 pa-0"

      >

        <v-card
          v-if="mimeType === 'video'"
          flat
          tile
          class="mx-auto black align-center justify-center ma-0 pa-0"
          height="100vh"
          width="100%"
        >
          <video
            poster="/src/assets/logo.png"
            id="video"
            ref="video"
            class="vidEmbed"
            autoplay
            muted
            width="100%"
            height="100%"
            loop
            :src="filePath"
            @onload="hideLoading()"
            @error="videoError()"
          ></video>
        </v-card>

        <v-img
          v-if="mimeType === 'image'"
          height="100vh"
          width="100vh"
          contain
          class="mx-auto black align-center justify-center ma-0 pa-0"
          :src="filePath"
          @error="imageError()"
          @loadstart="showLoading()"
          @load="hideLoading()"
        >
        </v-img>

        <v-card
          v-if="mimeType === 'application'"
          flat
          tile
          class="mx-auto black align-center justify-center ma-0 pa-0"
          height="100vh"
          width="100%"
        >
          <iframe
            id="interactiveX"
            loading="lazy"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            allowTransparency="false"
            :src="filePath"
            @error="applicationError()"
            @loadstart="showLoading()"
            @load="hideLoading()"
          ></iframe>
        </v-card>
      
      </v-container>
      <v-footer v-show="mouseMoving" fixed color="transparent">


<!--
<v-card
v-if="this.dataCurrentFrameToken === null"
flat
  class="ml-2 rounded-lg"
  width="344"
  outlined
>

<v-list-item three-line>
    <v-list-item-content>
   
      <v-list-item-title class="text-h5 mb-1">
    FRAME {{urlFrameID}}
      </v-list-item-title>

      <v-list-item-subtitle>Loading...</v-list-item-subtitle>

      <v-list-item-subtitle class="mt-4">{{ urlFrameUserWallet }}</v-list-item-subtitle>

<v-list-item-title class="mt-2">
    <v-progress-circular
     v-if="!loadedEntries"
    indeterminate
    color="grey"
  ></v-progress-circular>
</v-list-item-title>

    </v-list-item-content>
  </v-list-item>
</v-card>
-->


<!--
<v-card
v-if="this.dataCurrentFrameToken !== null"
flat
  class="ml-2 rounded-lg"
  width="344"
  outlined
>
  <v-list-item three-line>
    <v-list-item-content>
   
      <v-list-item-title class="text-h5 mb-1">
        {{ this.dataCurrentFrameToken.name  }}
      </v-list-item-title>
    
   
      <v-list-item-subtitle v-if="this.dataCurrentFrameToken.artist_profile !== null && this.dataCurrentFrameToken.artist_profile!== undefined">{{getUsername(this.dataCurrentFrameToken.artist_profile.alias,this.dataCurrentFrameToken.artist_address) }}</v-list-item-subtitle>

    </v-list-item-content>
  </v-list-item>
 
  <v-card-actions>
    <v-btn
      outlined
      rounded
      text
      small
      @click="openLink('https://objkt.com/asset/'+dataCurrentFrameToken.fa2_address+'/'+dataCurrentFrameToken.token_id)"
    >
      Collect on Objkt.com
    </v-btn>
    <v-spacer></v-spacer>
<v-btn  @click="shareViaWebShare(openFrameURL(urlFrameID),'Check this DIYFRAME out')" text small outlined
      rounded><v-icon left>mdi-share-variant</v-icon> </v-btn>

  </v-card-actions>

</v-card>
-->
    <v-card
      flat
       color="transparent"
      width="100%"
    outlined
    >

 <v-card-actions>
  <!--
 <v-btn
    
color="black"
      class="white"
     
     
      large
      
      outlined
      
      @click="openLink('https://diyframe.xyz/?ref=frame')"
     
    >
     <img height="30" class="mr-4" src="@/assets/logo.png" />DIYFRAME</v-btn>


     mdi-repeat-once
   
     mdi-camera-timer
-->

   
<!-- 
SHOW DETAILS OF PIECE
<v-btn>
        <v-icon>mdi-smart-card-outline</v-icon>
      </v-btn>
-->

<!--
SPÄTER EINBAUEN

        <v-btn>
          show stats OGprice and/or floor, etc
        <v-icon>mdi-skip-previous</v-icon>
      </v-btn>


     

 

       
   
      <v-btn @click="volumeOn = false" v-if="volumeOn">
        <v-icon>mdi-volume-high</v-icon>
      </v-btn>
     <v-btn  @click="volumeOn = true" v-if="!volumeOn">
        <v-icon>mdi-volume-off</v-icon>
      </v-btn>

-->

     <v-btn 
      color="black"
      class="white"
          
             outlined
             @click="toggleFullScreen()">
             <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-200v-200h80v120h120v80H200Zm0-360v-200h200v80H280v120h-80Zm360 360v-80h120v-120h80v200H560Zm120-360v-120H560v-80h200v200h-80Z"/></svg>
      </v-btn>


<!-- TODO IMAGE MIT REIN 
  <v-btn  @click="shareViaWebShare(openFrameURL(item[0].diyframe_frame_id),'Check out this DIYFRAME')">
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
-->



<!-- 
TODO SPÄTER ADDEN
<v-menu>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
         
           color="black"
      class="ml-2 white"
          
             outlined
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
                 <v-icon>mdi-av-timer</v-icon>   
          </v-btn>
        </template>
        <span>Setup cycle interval between NFTs</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in itemsInterval"
        :key="index"
        @click.native="cycleInterval = (item.interval*1000)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

   <v-btn  color="black"
      class="white"
          
             outlined
             @click="nextPieceInFrame('prev')">
        <v-icon>mdi-skip-previous</v-icon>
      </v-btn>

     <v-btn  color="black"
      class="white"
          
             outlined
             @click="nextPieceInFrame('next')">
        <v-icon>mdi-skip-next</v-icon>
      </v-btn>
      -->

<v-spacer></v-spacer>

<v-btn href="https://diyframe.xyz/?ref=btnbttm" target="_blank">
         <img height="30" src="@/assets/logo.png" /></v-btn>

 </v-card-actions>
    </v-card>
  </v-footer>

    <v-btn
      v-show="loadingInfo"
      color="black"
      fab
      dark
      small
      fixed
      bottom
      right
    >
      <v-progress-circular
        indeterminate
        color="grey"
      ></v-progress-circular>
    </v-btn>
</template>
      

<!-- #showframe -->

<!-- 
<template v-if="this.$route.path ==='/' && this.$route.path === '/frames' && this.$route.path ==='explore'  && this.$route.path === 'about'  && this.$route.path === 'home' ">
  -->
  

<!-- /APP -->

  </v-app>
</template>


<script>
// #import #b
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";

const query = `query GetToken($fa2Address: String = "", $tokenId: String = "") {
    tokens_by_pk(fa2_address: $fa2Address, token_id: $tokenId) {
        name
    artist_profile {
        alias
      }
      artist_address
       objkt_artist_collection_id
      
       fa2_address
      token_id
      platform
       artifact_uri
    display_uri
    thumbnail_uri
    mime_type
    }  
}
`;







// #data #r
export default {
   data() {
    return {
      wakeLockRequested: null,
      cycleCollectionTimer: null, 
      cycleInterval: 10000, //default: 10000
      loadingInfo: false,
      serverItemsFrame: [],
      mimeType: "application",
      mouseMoving: false,
      timer: null,
      filePath: null,
      currentArtwork: 0,
      valid: true,
    frameID: '',
    frameIDRules: [
      v => !!v || 'ID is required'
    ],
    }},
  computed: {
    ...mapGetters(["getLoading","getConnected","getPkh"]),
  },
  // #ted
    mounted() {
 console.log("mounted() >this.$route. ",this.$route);

if(this.$route.params.id === undefined) {
console.log("NO ID shortID missing");
} else {

}

this.fetchFrame(this.$route.params.id) 



this.cycleCollectionTimer = setInterval(() => {  
  this.nextArtwork()
}, this.cycleInterval)
 //
 if ('wakeLock' in navigator) {
  console.log('❓ Wake Lock API Supported 🎉')
this.requestWakeLock()
}
 //
  },
  //
  watch: {
    getConnected(newValue) {
      if (newValue) {
     console.log("newValue ",newValue);
     console.log(this.getPkh)

     if(this.getPkh !== undefined && this.getPkh !== null){
      this.loadFrame(this.getPkh)
     }
      }
    }
  },
  methods: {
    ...mapActions(["fetchFrame"]),
   
    async requestWakeLock() {
try {
   this.wakeLockRequested = await navigator.wakeLock.request('screen')
} catch (error) {
  console.error("Error wakeLock :", error);
}
},
    validate () {
      console.log("this.$refs.form.validate() ",this.$refs.form.validate());
       if(this.$refs.form.validate()) {
        this.$router.push(this.frameID)
        this.fetchFrame(this.frameID) 

    }
      },
    imageError() {
      console.log('imageError Loading')
    },
    videoError() {
      console.log('videoError Loading')
    },
    applicationError() {
      console.log('applicationError Loading')
    },
    getMimeType(val) {
if(val === null) {
  return "application"
}
      if(val.indexOf('image') !== -1){
return "image"
      } else if(val.indexOf('video') !== -1){
return "video"
      } else if(val.indexOf('application') !== -1){
return "application"
      }  else {
return "application"
      }
    },
    nextArtwork() {
      
   if(this.serverItemsFrame.length > 0) {
      this.currentArtwork++
  
if(this.currentArtwork < 0) {
 this.currentArtwork = this.serverItemsFrame.length-1
}

if(this.currentArtwork === this.serverItemsFrame.length ) {
this.currentArtwork = 0
}

this.mimeType = this.getMimeType(this.serverItemsFrame[this.currentArtwork].mime_type)
// TODO index of -1
if(this.mimeType === "video") {

  let artifact = this.serverItemsFrame[this.currentArtwork].artifact_uri
  artifact = artifact.split('ipfs://').join('https://ipfs.io/ipfs/')
  this.filePath = artifact

} else{
this.filePath = this.IPFStoHTTPbutiq(this.serverItemsFrame[this.currentArtwork])
}

console.log(this.filePath );
console.log(this.mimeType );
}      
    },
    toggleFullScreen(event) {
 var element = document.body;

	if (event instanceof HTMLElement) {
		element = event;
	}

	var isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

	element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () { return false; };
	document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function () { return false; };

	isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
      // document.documentElement.requestFullscreen({ navigationUI: 'hide' })
    },

    showLoading() {
      // console.log('showLoading')
      this.loadingInfo = true
    },
    hideLoading() {
     //  console.log('hideLoading')
      this.loadingInfo = false
    },
    showError() {
     //  console.log('showError')
      this.loadingInfo = false
    },
          mouseMove() {
      this.mouseMoving = true
      document.documentElement.style.cursor = 'auto';


      if (this.timer === null) {
        this.timer = setInterval(() => {
          this.mouseMoving = false
          document.documentElement.style.cursor = 'none';
          clearInterval(this.timer)
          this.timer = null
      
        }, 6000)
        //
      }
    }, 
    async fetchGraphQL(operationsDoc, operationName, variables) {
  const result = await fetch(
    "https://api.teztok.com/v1/graphql",
    {
      method: "POST",
      body: JSON.stringify({
        query: operationsDoc,
        variables: variables,
        operationName: operationName
      })
    }
  );
  return await result.json();
},
 
    async loadFrame(valUpdate) {
      //  loading = true 
      const values = valUpdate.split(',');
const pairs = [];

for (let i = 0; i < values.length; i += 2) {
  if (i + 1 < values.length) {
    pairs.push([values[i], values[i + 1]]);
  }
}

 for (let i = 0; i < pairs.length; i++) {
await  this.fetchSingleToken(pairs[i][0], pairs[i][1])
// this.loading = false;
          }
    },

    async fetchSingleToken(token, address) {
const { errors, data } = await this.fetchGraphQL(query, "GetToken", { "fa2Address": address, "tokenId": token });

if (errors) {
  console.error(errors);
} else {
   this.serverItemsFrame.push(data.tokens_by_pk)
   this.nextArtwork()
/*
   // TEMP:
   this.mimeType = 'image'
   // this.filePath = this.serverItemsFrame
   this.filePath = this.IPFStoHTTPbutiq(this.serverItemsFrame[0])
   */
}
},

pickTokenDisplayUri(token) {
  if (
    token.platform === 'FXHASH' &&
    token.thumbnail_uri &&
    token.thumbnail_uri === 'ipfs://QmbvEAn7FLMeYBDroYwBP8qWc3d3VVWbk19tTB83LCMB5S' &&
    token.fx_collection_display_uri
  ) {
    return {
      uri: token.fx_collection_display_uri,
    };
  }

  if (!token.display_uri && token.thumbnail_uri === 'ipfs://QmNrhZHUaEqxhyLfqoq1mtHSipkWHeT31LNHb1QEbDHgnc') {
    // HEN, early mints
    return {
      uri: token.artifact_uri,
      is_video: token.mime_type === 'video/mp4',
    };
  }

  if (
    token.platform === 'VERSUM' &&
    (token.mime_type === 'image/gif' ||
      token.mime_type === 'image/jpeg' ||
      token.mime_type === 'image/png' ||
      token.mime_type === 'image/jpg')
  ) {
    return {
      uri: token.artifact_uri,
    };
  }

  if (token.platform === 'VERSUM' && parseInt(token.token_id, 10) <= 27951 && token.mime_type === 'video/mp4' && token.formats !== undefined) {
    // the video was used in the display_uri in the early versum days. pick first jpg in formats instead.
    try {
      const jpgFormat = token.formats.find((format) => format.mime_type === 'image/jpeg');

      if (jpgFormat) {
        return {
          uri: jpgFormat.uri,
        };
      }
    } catch (err) {
      console.log("ERROR: ", err);
    }
  }

  return {
    uri: token.display_uri || token.thumbnail_uri,
  };
},

IPFStoHTTPbutiq(val) {
      val =  this.pickTokenDisplayUri(val)  
val = val.uri
      if (val) {
      
        return  val.split('ipfs://').join('https://ipfs.io/ipfs/')
      }
      // TODO else return not found pic?!
     // return '@/assets/logo.png'
      },

},

};
</script>
// use "cover" to avoid distortion
<style>
.vidEmbed {
        object-fit: contain; 
}
</style>
